<template>
  <v-dialog :value="modal" persistent>
    <v-card class="modal-card">
      <v-card-title class="modal-header">
        <div class="b-title">
          {{ history?.brand && history?.brand?.name }}
          <i @click="closeDialog" class="fas fa-close close"></i>
        </div>
        <div class="b-info">
          <div class="b-info-title">
            <h4
              >
              You purchased a total of {{ +history?.brand?.count }} pieces of branding for ${{
                +history?.brand?.price.split('/')[0]
              }}.
            </h4>
          </div>
          <div class="b-details">
            <p
              >At the moment you have:
              {{ history?.current_employee_branding?.curren_amount }}pcs.</p
            >
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="order_table">
          <table id="invoice__bill">
            <tr>
              <th
                :key="item"
                v-for="item in $t('ORDER_DETAILS.order_details_table')"
                >{{ item }}</th
              >
            </tr>
            <tr
              v-for="(selectedOrder, index) in selectedOrders && selectedOrders"
              :key="selectedOrder"
            >
              <td
                class="cursor-pointer"
                @click="$router.push({ name: 'invoice orders' })"
                >№{{ selectedOrders?.length - index }}</td
              >
              <td>{{ selectedOrder && selectedOrder.created_at }}</td>
              <td>{{
                selectedOrder?.reyestr
                  ? selectedOrder?.reyestr?.name[activeLang.language]
                  : 'Buying branding'
              }}</td>
              <td
                ><span class="total">{{ selectedOrder?.amount }}pcs.</span></td
              >
              <td>
                <span>{{ selectedOrder?.curren_amount }} pcs.</span>
              </td>
            </tr>
          </table>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'OrderModal',
  props: {
    modal: {
      default: true,
      type: Boolean,
    },
    selectedOrders: {
      type: Array,
    },
    history: {
      type: Object,
    },
  },
  methods: {
    closeDialog() {
      this.$emit('closeModal')
    },
  },
  mounted() {
    this.activeLang = this.$store.state.language
  },
}
</script>
<style scoped lang="scss">
.modal-card {
  width: 100vw; /* Устанавливаем ширину модального окна на весь экран */
  height: 100%; /* Устанавливаем высоту модального окна на весь экран */
}

.modal-header {
  position: sticky;
  top: 0;
  background-color: #fbfbfb;
}

.order_table {
  width: 100%;
  overflow-x: auto;
}
.b-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 25px;
}
.invoice__content {
  background-color: #ffffff;
  border-radius: 1px;
  padding: 27px;
  overflow-x: scroll;
}
.invoice__content::-webkit-scrollbar {
  width: 0;
}
#invoice__bill {
  width: 100%;
}

#invoice__bill tr td:first-child {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #423f3d;
}

#invoice__bill th {
  padding: 10px;
  text-align: start;
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18%;
  color: #423f3d;
  white-space: nowrap;
}

#invoice__bill td {
  padding: 8px;
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #a4a4a4;
  white-space: nowrap;
}
#invoice__bill .show {
  color: #423f3d;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #cfebcc;
  margin-right: 9px;
}

#invoice__bill tr:first-child {
  height: 40px;
  background-color: #fbfbfb;
}

#invoice__bill tr:not(:first-child) {
  height: 50px;
  border-bottom: 1px solid #f5f5f5;
  &:hover {
    background-color: #fbfbfb;
  }
}

.bill__title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 1px;
  color: #31343c;
}

.bill__nav {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;

  li:not(:last-child) a {
    &::after {
      content: '';
      background-image: url('/img/icons/arrow-right-icon.svg');
      background-repeat: no-repeat;
      display: inline-block;
      width: 5px;
      height: 8px;
      margin-left: 7px;
    }
  }

  a {
    font-family: 'Alegreya Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #818181;
  }
}
.b-details {
  display: flex;
  padding: 0;
  flex-direction: column;
  p {
    font-size: 13px;
    height: 20px;
  }
}
.b-info-title {
  h4 {
    font-size: 20px;
  }
}
.b-info {
  padding: 10px;
  padding-left: 0;
  padding-right: 0;
}
</style>
